.mylike-widget-container {
  list-style: none !important;
  padding-left: 0 !important;
  ul {
    list-style: none !important;
    margin-left: 0 !important;
    padding: 0 !important;
  }
  .like-info-container {
    padding: 16px;
  }
  h3 {
    @extend h4;
    font-family: unset !important;
    margin: 0 !important;
  }
  a {
    font-size: 15px !important;
  }
  .mylike-widget-like-detail {
    h3 {
      padding-top: 16px;
    }
  }
  .mylike-widget-menu {
    .filter-list {
      margin-top: 50px !important;
      max-height: calc(100% - 100px) !important;
    }
  }
  @media #{$phablet}, #{$mobile} {
    margin-top: 21px;
    .mylike-widget-map,
    .mylike-widget-menu,
    .mylike-widget-like-detail {
      height: calc(100vh - 300px) !important;
    }
  }
  @media #{$tablet-up}, #{$tablet}, #{$desktop}, #{$tv} {
    .mylike-widget-list,
    .mylike-widget-map,
    .mylike-widget-menu,
    .mylike-widget-like-detail {
      height: calc(100vh - 250px) !important;
    }
  }
}
