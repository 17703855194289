/* Swiper */

.slider,
app-swiper {
  .slider-content {
    display: block;
  }

  .swiper-button-next,
  .swiper-button-prev {
    &:not(.video) {
      height: 100%;
    }
    &.video {
      top: 17px !important;
    }
    background-image: none !important;
    position: absolute;
    vertical-align: middle;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    &::before {
      color: white;
      display: block;
      text-align: center;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      margin-top: -4px;
    }
    &::after {
      display: none;
    }
  }

  .swiper-button-next {
    right: 0 !important;
    &::before {
      content: '\203A ';
    }
  }

  .swiper-button-prev {
    left: 0 !important;
    &::before {
      content: '\2039 ';
    }
  }
}

app-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    &::before {
      position: absolute;
      top: 50%;
      height: 52px;
      width: 26px;
      margin: -39px 0 0 0;
      padding-top: 8px;
      font-size: 28px;
      opacity: 0.6;
      @include shadow(inset, 50px, 50px, 0, rgba(0, 0, 0, 0));
    }
  }
  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      &::before {
        opacity: 1;
        @include shadow(inset, 50px, 50px, 0, rgba(0, 0, 0, 0.2));
      }
    }
  }
  .swiper-slide {
    height: 100%;
    background-position: center;
    background-size: cover;
    &.center {
      text-align: center;
    }
  }
  img {
    height: 100%;
    width: auto;
  }
  video {
    width: 100%;
    height: auto;
  }
}

#swiperLightbox {
  .column {
    img {
      cursor: pointer;
    }
  }
  .close {
    width: 52px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    z-index: 1;
  }
  .close,
  .prev,
  .next {
    border-radius: $round-radius;
  }
}
