form,
.detract {
  fieldset:not([class*='sc-straiv']) {
    border: none;
    margin: 0 auto;
    max-width: 800px;

    &.co-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    @media #{$tablet-up}, #{$phablet} {
      max-width: 600px;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  @media #{$mobile} {
    margin: auto;
  }
  @media #{$tv} {
    max-width: 800px;
    margin: 0 auto;
  }

  &.ng-invalid.ng-touched {
    .form-box {
      input.ng-invalid.ng-touched,
      ng-select.ng-invalid.ng-touched,
      textarea.ng-invalid.ng-touched,
      signature-pad canvas,
      international-phone-number.ng-invalid.ng-touched,
      international-phone-number.ng-invalid.ng-touched input {
        & ~ label.error {
          display: block;
        }

        border-color: $error-color;
      }
    }
  }
}

international-phone-number {
  .flag {
    background-image: url('../images/international_phone_number/flags.jpg') !important;
  }
}

app-pms-fellows {
  display: block;
}

/*==================
* form-box
===================*/
$box-padding: 24px;

.form-box {
  @extend .shadow;
  padding: $box-padding;
  text-align: left;
  margin: 16px auto;
  background: white;

  &.no-shadow,
  .form-box {
    &:not(.with-pad) {
      padding: 0;
    }

    box-shadow: none;
  }

  .counter {
    position: absolute;
    right: 24px;
    top: 53%;

    i {
      color: $grey;
      padding: 0 0 0 3.6px;
    }
  }

  form {
    max-width: none !important;
  }

  div.menu {
    width: 100%;
  }

  .divider {
    margin: 16px 0;
    border-top: 1px solid $mercury-grey;
  }

  label:not(.redesign) {
    @extend .font-sub-headline;
    color: $emperor-grey;
    display: block;
    position: relative;
    margin: 0;
    padding-bottom: 8px;
    line-height: 1.33;
    padding-right: 16px;

    &.error {
      @extend .font-error;
      display: none;
      width: 100%;
      margin: 8px auto 0;
      padding-bottom: 0;
      color: $error-color !important;
    }

    // Validations

    &:after {
      float: right;
      height: 24px;
      font-size: 12px;
      line-height: 2;
      text-align: right;
    }

    &.required {
      &:after {
        content: attr(data-after);
        color: $error-color;
      }

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        margin: 7px 0 0 4px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
        background-color: $error-color;
        content: '';
      }
    }

    &.valid,
    &.disabled {
      &:after {
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &.valid {
      &:after {
        content: '\f00c';
        color: $green;
      }
    }

    &.disabled {
      &:after {
        content: '\f023';
        color: $emperor-grey;
      }
    }

    // Caption

    & + small {
      @extend .caption-text;
      line-height: 1.5;
      padding: 8px 0;
      width: 100%;
    }
  }

  &.overlay {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%, -50%);
    -moz-transform: translateX(-50%, -50%);
    -ms-transform: translateX(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 450px;
    width: 100%;

    button {
      margin: 0 auto;
    }
  }
  textarea,
  input:not([type='checkbox']) {
    border: none;
    border-bottom: solid 2px $grey;
    height: 46px;
    padding: 0;
    width: 100%;
    resize: vertical;

    &[type='time']::-webkit-date-and-time-value {
      text-align: left;
      color: black;
    }

    &:disabled {
      color: $emperor-grey;
      cursor: not-allowed;
      -webkit-text-fill-color: $emperor-grey;
      opacity: 1;
    }
  }

  textarea {
    min-height: 90px;
  }

  &.no-input-height {
    textarea,
    input {
      height: auto;
    }
  }

  .open-dropdown {
    padding-top: 8px;

    .radio-container {
      width: 100%;
      min-height: 56px;
      text-align: left;
      position: relative;
      cursor: pointer;
      margin: 8px 0;

      input {
        position: absolute;
        border: none;
        cursor: pointer;

        &.custom-radio {
          height: 100%;
          border-radius: 4px;
          background-color: $alabaster-grey;
          border: none;
          padding: 0;

          &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
          }
        }
      }

      label:not(.error) {
        padding: 16px;
        border-radius: 4px;
        vertical-align: middle;
        cursor: pointer;
      }

      label.error {
        background: white;
        width: auto;
        padding: 8px 0 0 0 !important;
      }
    }
  }

  .date-button.selected {
    font-weight: bold;
  }
}

.form-box,
.checkboxgroup {
  div.checkbox input.checkbox {
    position: absolute;
  }
}

.modal-box {
  .form-box {
    box-shadow: none;
    margin: 0 auto;
  }
}

#container.wizard:not(.cico) {
  @media #{$tablet}, #{$tablet-up}, #{$phablet} {
    .form-box {
      padding: 16px;
      margin: 8px 0 8px 0 !important;
      width: 100%;
    }

    input:not(.custom-radio, .redesign) {
      height: 30px;
    }

    .radio-container {
      min-height: 35px;

      label {
        padding: 12px;
      }
    }

    international-phone-number {
      button {
        height: 30px;
      }
    }
  }
}

div.checkbox + div.checkbox {
  margin-top: 16px;
}

input.checkbox {
  position: absolute;
  width: 24px;
  height: 24px !important;
  padding: 0;
  margin: 0;
  border: solid 2px;
  border-radius: 1px;

  transition: all 0.15s ease-out 0s;
  display: inline-block;
  *overflow: hidden;

  &:checked {
    &::before {
      position: absolute;
      content: '\2713';
      top: 0;
      left: 0;
      display: inline-block;
      line-height: 21px;
      font-size: 20px;
      padding-left: 3px;
    }

    &::after {
      content: '';
      display: block;
      position: relative;
      z-index: 0;
    }
  }

  & + label {
    display: block;
    padding-left: 40px;
    cursor: pointer;
  }
}

.half {
  > div {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }

    &:only-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

app-form-send-cancel {
  &.kiosk {
    display: none;
    @media #{$mobile} {
      display: block;
    }
  }
}

.caption-text {
  color: $boulder-grey;
  margin-top: -13px;
  margin-bottom: 13px;
  display: block;
  font-size: 13px;
}

input:not([class*='sc-straiv']),
textarea:not([class*='sc-straiv']) {
  @extend .disable-styling-input;
  @extend .button-radius;
  @extend .font-input;
  font-family: 'Open Sans', sans-serif !important;
  margin: 0;
  position: relative;
  cursor: pointer;
  background-color: transparent;

  &.preview_input {
    display: inline-block;
    width: calc(94% - 80px);
    min-width: calc(94% - 80px);
    float: right;
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $alabaster-grey inset;
  }

  & ~ label.error {
    display: none;
  }

  outline: none !important;

  &:focus {
    outline: none !important;
  }
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: $grey;
  opacity: 1;
}

.ng-select {
  &.ng-select-single {
    .ng-select-container {
      @extend .font-input;
      @extend .disable-styling-input;
      @extend .button-radius;
      font-family: 'Open Sans', sans-serif !important;
      border: none;
      border-bottom: solid 2px $grey !important;
      background-color: transparent;
      height: 48px;
      color: black;
      cursor: pointer;

      .ng-value-container {
        padding-left: 0;
        margin-right: 5px;

        .ng-value {
          max-width: calc(
            100vw - 47px - (2 * #{$box-padding}) - (2 * 16px)
          ); // 47px are arrow / clear + margin padding of select
        }

        .ng-placeholder {
          color: $grey !important;
        }
      }

      &:hover {
        @extend .no-shadow;
      }
    }

    .ng-option {
      border-bottom: 1px solid rgba(124, 124, 124, 0.21);

      &.ng-option-child {
        padding-left: 26px;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .ng-option-disabled.ng-optgroup {
      border-bottom: 1px solid rgba(124, 124, 124, 0.1);
    }

    .form-input {
      margin: 0;
    }

    .ng-input {
      padding-left: 0 !important;
      top: 0 !important;
    }

    &.ng-select-focused.ng-select-opened {
      .ng-value {
        color: $mercury-grey;
      }
    }

    .ng-clear {
      vertical-align: bottom;
    }

    & + label.error {
      display: none;
    }
  }

  &.ng-invalid.ng-touched {
    .ng-select-container {
      border-color: $error-color !important;
    }
  }

  @media #{$super-tiny} {
    .ng-dropdown-panel {
      z-index: 5001 !important;
    }
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      white-space: normal !important;
      text-overflow: clip !important;
      overflow: visible !important;
    }

    .ng-optgroup {
      font-style: italic;
      background-color: $alto-grey;
    }
  }
}

app-form-send-cancel {
  &.kiosk {
    display: none;
    @media #{$mobile} {
      display: block;
    }
  }
}

.uploader {
  label {
    padding: 16px;
    text-align: center;
    border-radius: 2px;
    border-bottom: 2px solid $grey;
    transition: all 0.2s ease;
    user-select: none;

    span {
      display: block;
      padding: 40px;
      cursor: pointer;
    }
  }

  #file-image {
    display: inline;
    margin: 0 auto;
    width: auto;
    height: auto;
    max-height: 300px;
  }

  input {
    &[type='file'] {
      display: none;
    }

    &.ng-invalid.ng-touched + input + label,
    &.ng-invalid.ng-touched + label {
      border-bottom-color: $error-color;
    }
  }

  .bottom {
    @extend .clearfix;
    margin-top: 16px;
    text-align: right;

    a {
      text-decoration: none !important;

      &.first-child {
        color: $charade-grey !important;
        float: left !important;
      }

      &:only-child,
      &:last-child {
        color: $silver !important;
        float: right !important;
      }
    }

    div {
      display: inline-block;

      &:not(:last-child),
      &:not(:only-child) {
        margin-right: 10px;
      }
    }
  }
}

international-phone-number {
  .dropdown {
    width: 58px;
  }

  .dropbtn {
    background: none !important;
    border: none !important;
    border-bottom: solid 2px $grey !important;
    border-radius: 1px;
    height: 46px;
    width: 100%;

    button {
      vertical-align: bottom;
      margin-right: 0 !important;

      .defaultCountry {
        width: 16px;
      }
    }
  }

  & + label.error {
    display: none;
  }

  .flag {
    margin-right: 4px;
  }

  input {
    width: calc(100% - 58px) !important;
    vertical-align: bottom;
  }

  .dropdown-content {
    border: 1px solid $alto-grey;
    border-top: none;
    z-index: 1;
  }

  .list-group-item {
    border-bottom: 1px solid rgba(124, 124, 124, 0.21);
    padding: 7px 16px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    text-decoration: none !important;
  }

  &.ng-touched.ng-invalid {
    .dropbtn {
      border-bottom-color: $error-color !important;
    }
  }
}

/*===================
* Buttons
====================*/
button {
  font-family: inherit;
}

.button {
  background: $tundora-grey;
  color: white;
  padding: 16px 26px;
  display: block;
  position: relative;
  text-align: center;
  border: none;
  @extend .font-button;
  @extend .button-radius;

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &.near {
    margin-top: 0;
  }

  &.i-block {
    display: inline-block;
  }

  &.inline-d {
    display: inline;
  }

  &.wide {
    width: 100%;
  }

  &.form {
    @extend .disable-styling-input;
    @extend .font-input;
    @extend .button-radius;
    @extend .no-shadow;
    margin: 0;
    text-align: left;
    border: 1px solid $alto-grey;
    font-weight: 400 !important;
    text-decoration: none !important;
  }

  &.green {
    background: $green;
    color: white !important;

    &:active,
    &:hover {
      background: $green-hover;
    }
  }

  &.red {
    background: $red;
    color: white !important;

    &:active,
    &:hover {
      background: $red-hover;
    }
  }

  &.light {
    background: #ebebec;
    color: #666 !important;

    &:active,
    &:hover {
      background: $alabaster-grey;
      color: black !important;
    }
  }

  &.grey {
    background: $alabaster-grey;
    color: black !important;
  }

  &.ghost {
    background: white;
    color: $grey !important;
    box-shadow: none;

    &:active,
    &:hover {
      background: $alabaster-grey;
    }
  }

  &.action {
    text-align: left;
    background: white;
    color: $emperor-grey;
    box-shadow: none;
    padding: 16px 10px;
    width: 100%;

    &:active,
    &:hover {
      background: $alabaster-grey;
    }

    i {
      margin-right: 16px;
    }
  }

  &.top-btn {
    text-decoration: none;
  }
}

.button-hollow-small {
  height: 18px;
  line-height: 18px;
  padding: 5px 12px;
  border: 1px solid;
  box-sizing: content-box;
  @extend .button-radius;
  @extend .font-widgetbutton;
  font-weight: normal !important;
  text-decoration: none !important;

  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.14);
  }
}

div.button-grid {
  @extend .clearfix;
  padding: 14px 0;
  cursor: auto;
  text-align: center;

  &:focus {
    outline: none;
  }

  .button {
    width: 47%;
    float: left;
    margin: 0 auto;
    padding: 16px 0;
    text-align: center;
    display: inline-block;

    &:first-child {
      margin-right: 6%;
    }

    &:only-child {
      float: none;
      margin: 0 auto;
    }
  }

  &.stacked {
    .button {
      display: block;
      float: none;
      width: 90%;
      margin: 8px auto;
    }
  }
}

.button-group {
  &.dropdown {
    margin: auto;

    .button {
      width: calc(100% - 110px);
    }

    .button.dropdown {
      margin-left: -4px;
      border-left: none;
      width: 55px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }

  .button {
    display: inline-block;
  }
}

.dropdown-menu {
  border-left: 1px solid $concrete-grey;
  border-right: 1px solid $concrete-grey;

  &.show {
    display: table !important;
  }

  a:first-of-type {
    border-top: none;
  }
}

.adyen-input {
  @extend input;
  padding: 0 16px;
  background: $alabaster-grey !important;
  height: 59px;
}

.adyen-checkout__payment-methods-list {
  padding-left: 0 !important;
}

app-datepicker {
  position: relative;

  span :not(.redesign) {
    padding: 0 8px 2px 8px;
    font-size: 14px;
    color: $emperor-grey;

    &:first-of-type {
      padding-left: 0;
    }
  }

  input#date_datepicker {
    cursor: pointer;

    &:disabled,
    &:read-only,
    &.disabled,
    &.read-only {
      cursor: not-allowed;
      -webkit-text-fill-color: $emperor-grey;
      opacity: 1;
    }

    &.ng-invalid.ng-touched {
      border-color: $error-color;
    }
  }

  input#text_datepicker {
    cursor: text;

    &:disabled,
    &:read-only,
    &.disabled,
    &.read-only {
      cursor: not-allowed;
      -webkit-text-fill-color: $emperor-grey;
      opacity: 1;
    }

    &.ng-invalid.ng-touched {
      border-color: $error-color;
    }
  }

  input#mobile_datepicker::-webkit-date-and-time-value {
    text-align: left;
  }

  input[type='text'] {
    color: $emperor-grey !important;
  }

  input[type='date']::before {
    color: $emperor-grey !important;
    content: attr(placeholder);
  }

  input[type='date'] {
    color: #ffffff;

    &:focus,
    &:valid {
      color: $emperor-grey;

      &::before {
        content: '' !important;
      }
    }
  }

  .mat-input-element:disabled {
    -webkit-text-fill-color: $emperor-grey;
    opacity: 1;
    cursor: not-allowed;
  }

  .mat-datepicker-toggle {
    position: absolute;
    top: -9px;
    right: 8px;
    width: 100%;
  }

  .mat-form-field-infix {
    padding: 0;
    border: 0;
  }

  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-infix {
    width: 100%;
    position: absolute;
    padding-top: 0.3em;
  }

  .mat-form-field-suffix {
    width: 100%;
  }

  .mat-button-base {
    position: absolute;
    right: -16px;

    svg {
      width: 18px;
      height: 18px;
      color: black;
    }
  }

  .mat-button-ripple {
    display: none;
  }

  .mat-button-focus-overlay {
    display: none;
  }

  .mat-icon-button {
    background-color: transparent;
    border-radius: 0;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.mat-calendar-body-cell-content {
  border-radius: $round-radius !important;
}
