@import 'animations';

@mixin grid-padding-x() {
  padding-right: 4px;
  padding-left: 4px;

  @media #{$tiny} {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@mixin grid-padding-y() {
  padding-top: 25px;
  padding-bottom: 22px;

  @media #{$tablet} {
    padding-top: 0;
    padding-bottom: 44px;
  }
  @media #{$desktop}, #{$kiosk} {
    padding-bottom: 60px;
  }
}

@mixin wizard-grid-padding-x() {
  padding-right: 43px;
  padding-left: 43px;

  @media #{$all-tablet} {
    padding-right: 50px;
    padding-left: 50px;
  }
  @media #{$desktop} {
    padding-right: 321px;
    padding-left: 321px;
  }
  @media #{$kiosk} {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@mixin wizard-grid-padding-y() {
  padding-top: 30px;
  padding-bottom: 22px;

  @media #{$all-tablet} {
    padding-top: 40px;
    padding-bottom: 44px;
  }
  @media #{$desktop}, #{$kiosk} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@mixin grid-gap() {
  row-gap: 12px;
  column-gap: 8px;

  @media #{$all-tablet}, #{$desktop}, #{$kiosk} {
    column-gap: 12px;
  }
}

@mixin shadow($a: 0, $b: 0, $c: 0, $d: 0, $color: #000) {
  -moz-box-shadow: $a $b $c $d $color;
  -webkit-box-shadow: $a $b $c $d $color;
  box-shadow: $a $b $c $d $color;
}

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}

@mixin transition($type, $duration, $style) {
  -webkit-transition: $type $duration $style;
  -moz-transition: $type $duration $style;
  -ms-transition: $type $duration $style;
  -o-transition: $type $duration $style;
  transition: $type $duration $style;
}

/** flex mixins **/

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-grow($int: 1) {
  -webkit-box-flex: $int;
  -moz-box-flex: $int;
  -webkit-flex-grow: $int;
  -ms-flex: $int;
  flex-grow: $int;
}

@mixin flex-wrap($value: nowrap) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -ms-flex: $values;
  -webkit-flex: $values;
  flex: $values;
}

@mixin flex-direction($direction) {
  @if $direction == column {
    -webkit-flex-direction: vertical;
    -moz-flex-direction: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  } @else {
    -webkit-flex-direction: horizontal;
    -moz-flex-direction: horizontal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}

/** gradient mixins **/

@mixin linearGradientHorizontal($left, $right) {
  background: $right; /* Old browsers */
  background: -moz-linear-gradient(left, $left 0%, $right 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, $left),
    color-stop(100%, $right)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, $left 0%, $right 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, $left 0%, $right 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, $left 0%, $right 100%); /* IE10+ */
  background: linear-gradient(to right, $left 0%, $right 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0); /* IE6-9 */
}

/** helper **/

.disable-styling-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.no-events {
  pointer-events: none;
}

.no-shadow {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.shadow {
  @include box-sizing;
  position: relative;
  border-radius: 4px;
  box-shadow:
    0 4px 6px 0 rgba(0, 0, 0, 0.1),
    0 0 4px 0 rgba(0, 0, 0, 0.06);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clearfix:after {
  content: '.';
  clear: both;
  display: block;
  visibility: hidden;
  font-size: 0;
  height: 0;
}

.scrollbar {
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: $concrete-grey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &::-webkit-scrollbar {
    display: block;

    width: 16px;
    margin: 0 48px;
  }
}

.animation-in {
  @include animation('fade 0.3s ease-in');
}

.animation-out {
  @include animation('fade 0.3s ease-out');
}
