@import './variables';

button.btn-filled,
button.btn-outline {
  @include transition(background-color, 400ms, ease-in-out);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 2px;
  width: 274px;
  height: 40px;
  margin: 0;
  font-size: 13px !important;

  @media #{$all-tablet} {
    width: 620px;
    height: 50px;
    font-size: 16px !important;
  }

  @media #{$desktop}, #{$kiosk} {
    width: 303px !important;
    height: 60px;
    font-size: 20px !important;
  }

  &.small-width {
    &.btn-outline {
      width: 120px;
    }

    &.btn-filled {
      width: 140px;
    }

    @media #{$all-tablet} {
      &.btn-outline,
      &.btn-filled {
        width: 295px;
      }
    }

    @media #{$desktop}, #{$kiosk} {
      &.btn-outline,
      &.btn-filled {
        width: 274px;
      }
    }
  }

  &.btn-filled {
    border: none !important;
  }

  &:hover {
    cursor: pointer;
  }

  &.btn-outline {
    background-color: transparent !important;
    border: 1px solid;
  }

  &:disabled {
    color: white !important;
    border-color: $disabled-color !important;
    background-color: $disabled-color !important;
    cursor: default;
  }
}

.btn-grid {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;

  &.half-fill {
    @media #{$all-tablet} {
      button {
        width: 50%;
      }
    }
  }
}
