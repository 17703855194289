body.no-sidebar {
  .ds-wrapper {
    --base-margin-right: calc(300px - #{$width-sidebar});
  }

  #web-messenger-container {
    right: calc(300px - #{$width-sidebar}) !important;
  }
}

.ds-wrapper {
  z-index: 5003 !important;

  .ds-footer {
    height: 0;
  }

  --base-margin-right: 300px;
  @media #{$tablet}, #{$mobile},  #{"only screen and (max-width: 890px)"} {
    --base-margin-right: calc(300px - #{$width-sidebar});
  }

  @media #{$tv}, #{$desktop}, #{$tablet}, #{$tablet-up}, #{$phablet} {
    .ds-chat {
      max-height: calc(100vh - 200px) !important;
    }
  }

  &.elevated {
    --base-margin-bottom: 85px;
  }

  &.elevated-mobile {
    @media #{$mobile, $phablet} {
      --base-margin-bottom-mobile: 10px;
    }
  }

  .ds-whatsapp-button {
    display: none !important;
  }

  &.ds-wrapper--pr {
    &::after {
      background: none !important;
    }
  }
}

#web-messenger-container {
  z-index: 1999 !important;
  right: 280px !important;
  @media #{$tablet}, #{$mobile}, #{"only screen and (max-width: 890px)"} {
    right: calc(280px - #{$width-sidebar}) !important;
  }

  @media #{$phablet}, #{$mobile} {
    max-height: calc(100vh - #{$header-height});
  }

  &.elevated {
    bottom: 85px;
  }

  &.elevated-mobile {
    @media #{$mobile, $phablet} {
      bottom: 80px;
    }
  }
}
