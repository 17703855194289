.font-navigation {
  font-size: 15px;
}

.font-overview-big {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -1px;
  @media #{$phablet}, #{$mobile} {
    font-size: 22px;
  }
}

.font-button {
  font-size: 17px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
  color: white;
  @media #{$mobile} {
    font-size: 14px !important;
  }
}
.font-headerfooter {
  font-size: 13px;
  font-weight: 600;
}
.font-widget-text {
  font-size: 14px;
}
.font-widgetbutton {
  font-size: 13px !important;
  font-weight: 700;
}
.font-widget-temperature {
  font-size: 27px;
  @media #{$phablet}, #{$mobile} {
    font-size: 25px;
  }
}
.font-widget-temperature-current {
  font-size: 40px;
  @media #{$phablet}, #{$mobile} {
    font-size: 25px;
  }
}
.font-subtext {
  font-size: 11px;
}
.font-news-container {
  font-size: 15px;
  @media #{$mobile} {
    font-size: 14px;
  }
}
.font-page-headline {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.66em;
  @media #{$mobile} {
    font-size: 26px;
  }
}
.font-sub-headline {
  font-size: 16px;
  font-weight: 700;
}
.font-article-name {
  font-size: 22px;
  font-weight: 600;
  @media #{$mobile} {
    font-size: 18px;
  }
}
.font-article-price {
  font-size: 32px;
  font-weight: 700;
  @media #{$mobile} {
    font-size: 24px;
  }
}
.font-wizard-modal-button {
  font-size: 20px;
  font-weight: 700;
  @media #{$mobile} {
    font-size: 18px;
  }
}
.font-list {
  font-weight: normal;
  font-size: 16px;
  @media #{$mobile} {
    font-size: 14px;
  }
}
.font-text,
.font-input {
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  @media #{$mobile} {
    font-size: 14px;
  }
  @media #{$tv} {
    font-size: 17px;
  }
}
.font-error {
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
  @media #{$mobile} {
    font-size: 14px;
  }
}
.font-tiles {
  font-size: 17px;
  font-weight: 400 !important;
  text-decoration: none !important;
  color: white;
}
.font-text-big {
  font-size: 19px;
  @media #{$mobile} {
    font-size: 17px;
  }
}
.font-text-big-bold {
  font-size: 19px;
  font-weight: 700;
  @media #{$mobile} {
    font-size: 17px;
  }
}
.font-copyright {
  font-size: 11px;
}

/*** Redesign ***/

.normal-font-size {
  font-size: 13px !important;

  @media #{$all-tablet} {
    font-size: 14px !important;
  }

  @media #{$desktop} {
    font-size: 18px !important;
  }

  @media #{$kiosk} {
    font-size: 20px !important;
  }
}

.smaller-font-size {
  font-size: 12px !important;

  @media #{$all-tablet} {
    font-size: 13px !important;
  }

  @media #{$desktop} {
    font-size: 16px !important;
  }

  @media #{$kiosk} {
    font-size: 18px !important;
  }
}

.input-font-size {
  font-size: 14px !important;

  @media #{$all-tablet} {
    font-size: 16px !important;
  }

  @media #{$desktop} {
    font-size: 18px !important;
  }

  @media #{$kiosk} {
    font-size: 20px !important;
  }
}
