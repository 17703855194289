.a2hs-bar {
  position: relative;
  z-index: 5004;
  background: $bright-background-color;
  padding: 24px 42px 18px 42px;
  line-height: 1.1;
  border-radius: 4px;
  border: 1px 0 1px 0 solid $alabaster-grey;
  box-sizing: border-box;

  .inner {
    position: relative;
    text-align: center;

    h3 {
      line-height: 1.3;
      margin: 0 0 25px 0;
      font-weight: bold;
      font-size: 20px;
    }

    ul {
      display: inline-block;
      margin: 4px 0 0 0;
      padding-left: 0;
      padding-bottom: 2px;

      li {
        text-align: left;
        list-style: none;
        padding: 8px 0;

        &:last-child {
          padding-bottom: 0;
        }

        div {
          display: inline-block;
          vertical-align: middle;

          &:first-child {
            width: 30px;
            text-align: center;
          }

          &:last-child {
            max-width: calc(100% - 30px);
          }

          &.opera-overflow {
            max-width: 110px;
            width: 110px;
          }

          span.fa-stack {
            margin: 0;
            width: 30px;
            height: 30px;
            line-height: 30px;

            .fa-ellipsis-vertical {
              margin: 8px;
            }

            .fa-rotate-45 {
              transform: rotate(45deg);
            }

            .fa-mobile-screen {
              font-size: 18px;
            }

            .fa-down-long {
              font-size: 15px;
              left: 4px;
              bottom: 2px;
            }

            .fa-up-long {
              font-size: 15px;
              left: -2px;
            }

            .fa-square {
              font-size: 15px;
            }

            .fa-arrow-up-long {
              font-size: 13px;
              bottom: 6px;
            }
          }
        }
      }
    }
  }

  &.chrome,
  &.edge {
    .inner {
      button {
        display: block;
        margin: 5px auto 0 auto;
        padding: 12px 60px;
      }
    }
  }

  &.safari,
  &.opera,
  &.firefox,
  &.samsung-internet,
  &.firefox.android,
  &opera.android,
  &.samsung-internet.android {
    padding: 24px 42px 18px 42px;

    .inner {
      div {
        padding-right: 0;
      }

      h3 {
        font-size: 18px;
      }

      .button.ghost {
        margin: 24px auto auto auto;
      }
    }
  }

  &.widget {
    &.safari,
    &.opera,
    &.samsung-internet,
    &.firefox.android,
    &.firefox {
      @media #{$tablet}, #{$phablet}, #{$mobile} {
        padding: 3px 18px;
      }
    }
  }
}

app-widget-a2hs {
  .a2hs-bar {
    position: relative;
    z-index: 1;
    min-width: auto;
    left: 0;
    bottom: 0;
    background: none;
    box-shadow: none;
    padding: 0;
    border: none;
    border-radius: 5px;
    @media #{$mobile} {
      width: auto;
    }

    &.chrome,
    &.edge {
      .inner {
        h4 {
          margin: 5px 0 10px 0;
          font-weight: 400;
        }
        button {
          padding: 12px 62px;
          margin: 5px auto 5px auto;
          font-weight: 400 !important;
          border-radius: 5px;
        }
      }
    }
    &.safari,
    &.opera,
    &.samsung-internet,
    &.firefox.android,
    &.firefox {
      .inner {
        h4 {
          @media #{$tablet}, #{$phablet}, #{$mobile} {
            margin: 0;
            padding: 0;
            line-height: 1.4;
          }
        }
      }
    }
  }
}
